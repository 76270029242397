<template>
  <v-container class="fill-heigt" fluid>
    <v-row class="justify-center" style="margin-top: -45px">
      <v-col cols="12" md="12">
        <v-alert
          v-model="alert"
          dense
          color="primaryLight"
          type="info"
          class="text-center black--text caption mb-0"
          dismissible
          ><span v-html="$t('alert_scheduled_interview_completed')"></span
        ></v-alert>
      </v-col>
    </v-row>

    <v-row>
      <div>
        <v-btn icon color="primary" @click="goBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
    </v-row>

    <v-row class="d-flex flex-row justify-space-between align-center my-10">
      <div>
        <v-select
          :items="selectInterview"
          :label="$t('concluded_canceled_interviews')"
          item-text="text"
          item-value="key"
          dense
          solo
          style="width: 320px"
          v-model="pageInterview"
          @change="selectInterviewPage()"
        ></v-select>
      </div>
      <div class="d-flex flex-row align-center">
        <!-- <div class="whirelab-conclud-folder">
          <v-badge
            :content="interviews.outstanding.length"
            :value="interviews.outstanding.length"
            bordered
            color="primary"
            overlap
          >
            <v-btn icon @click="go()">
              <v-icon large>mdi-folder-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <span class="whirelab-conclud-tooltip">{{
            $t("view_ongoing_interviews")
          }}</span>
        </div> -->
        <div class="text-right px-0 ml-6">
          <form v-on:submit.prevent="search()">
            <v-text-field
              style="text-decoration: captalize"
              v-model="text"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              dense
              outlined
              @click:append="search()"
              @input="search()"
            ></v-text-field>
          </form>
        </div>
      </div>
    </v-row>

    <v-row>
      <!-- <pre>{{ interviews }}</pre> -->
      <v-expansion-panels v-model="panel">
        <template v-for="interview in interviews.completed">
          <expansion-panel
            :interviewId="$route.params.id"
            :interviews="interview"
            :isCompleted="true"
            :vacancyId="interview[0].vacancyId"
            :key="interview.timestemp"
          />
        </template>
      </v-expansion-panels>
    </v-row>

    <add-recruter-dialog />
    <add-observer-dialog />
    <re-schedule-interview />
    <finish-interview />
    <reject-candidate-dialog />
    <re-select-candidate-dialog />
    <success-dialog />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { v4 as uuid } from "uuid";
import { GET_INTERVIEW_BY_ID_QUERY } from "../graphql/Query.gql";
import { UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION } from "../graphql/Subscraption.gql";
import { groupBy } from "@/utils";
import ExpansionPanel from "../components/panel/finished/ExpansionPanel.vue";
import AddRecruterDialog from "../components/AddRecruterDialog.vue";
import AddObserverDialog from "../components/AddObserverDialog.vue";
import ReScheduleInterview from "../components/ReScheduleInterview.vue";
import FinishInterview from "../components/FinishInterview.vue";
import RejectCandidateDialog from "../components/RejectCandidateDialog.vue";
import ReSelectCandidateDialog from "../components/ReSelectCandidateDialog.vue";
import SuccessDialog from "../components/SuccessDialog.vue";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";

export default {
  name: "ScheduledInterview",
  mixins: [replaceSpecialCharsMixins],
  components: {
    ExpansionPanel,
    AddRecruterDialog,
    AddObserverDialog,
    ReScheduleInterview,
    FinishInterview,
    RejectCandidateDialog,
    ReSelectCandidateDialog,
    SuccessDialog,
  },

  data: () => ({
    interview: {},
    text: "",
    alert: true,
    panel: null,
    pageInterview:""
  }),

  apollo: {
    interview: {
      query: GET_INTERVIEW_BY_ID_QUERY,
      variables() {
        return { id: this.$route.params.id };
      },
      fetchPolicy: "cache-and-network",
    },
    $subscribe: {
      updateInterviewSchedule: {
        query: UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION,
        result({ data }) {
          this.interview = data.updateInterviewSchedule;
        },
      },
    },
  },

  watch: {
    interview(val) {
      if (this.$route.query.candidateIdOrEmail) {
        const candidateIdOrEmail = this.$route.query.candidateIdOrEmail;
        let candidates = val.candidates;
        candidates = candidates.filter(
          (filterCandidate) =>
            filterCandidate.status === "approved" ||
            filterCandidate.status === "rejected" ||
            filterCandidate.status === "finished" ||
            filterCandidate.status === "canceled"
        );
        const findedInterviewIndex = candidates.findIndex(
          (findCompleted) =>
            (findCompleted.interviewee &&
              findCompleted.interviewee.id === candidateIdOrEmail) ||
            (!findCompleted.interviewee &&
              findCompleted.email === candidateIdOrEmail)
        );

        this.panel = findedInterviewIndex;
      }
    },
  },

  computed: {
    ...mapGetters({
      getBlockedCandidates: "candidate/getBlockedCandidates",
    }),
    selectInterview() {
      return [
        {
          key: "approved_candidates",
          text: this.$t("approved_candidates"),
        },
        { key: "scheduled_interviews", text: this.$t("scheduled_interviews") },
      ];
    },
    interviews() {
      const ZERO_NUMBER = 0;
      const blockeds = this.getBlockedCandidates;
      const candidates = this.interview.candidates;
      let list = candidates
        ? candidates.map((findCandidate) => {
            const findIndex = blockeds.findIndex(
              (blockedId) =>
                findCandidate.interviewee &&
                blockedId == findCandidate.interviewee.id
            );
            return {
              ...findCandidate,
              address: this.interview.address,
              subject: this.interview.subject,
              telephone: this.interview.telephone,
              message: this.interview.message,
              vacancy: this.interview.vacancy.title,
              vacancyId: this.interview.vacancy.id,
              entity: this.interview.entity.name,
              timestemp: uuid(),
              blocked: findIndex >= ZERO_NUMBER,
            };
          })
        : [];

      const outstanding = list.filter(
        (filterCandidate) =>
          filterCandidate.status !== "approved" &&
          filterCandidate.status !== "rejected" &&
          filterCandidate.status !== "finished" &&
          filterCandidate.status !== "canceled"
      );

      list = list.filter(
        (filterCandidate) =>
          filterCandidate.status === "rejected" ||
          filterCandidate.status === "finished" ||
          filterCandidate.status === "canceled"
      );

      if (this.text) {
        const text = this.replaceSpecialChars(this.text);
        list = list.filter((c) =>
          this.replaceSpecialChars(c.interviewee.fullName).includes(text)
        );
      }
      list = list.map((mapItem) => {
        switch (mapItem.status) {
          case "finished":
            mapItem.sort = 1;
            break;

          case "rejected":
            mapItem.sort = 2;
            break;

          default:
            mapItem.sort = 3;
            break;
        }

        return mapItem;
      });

      list.sort((a, b) => a.sort - b.sort);

      const completed = groupBy(list, "timestemp", (record, dateKey) => {
        return [record[dateKey]];
      });

      let resultItems = { outstanding, completed };

      resultItems.completed = groupBy(list, "timestemp", (record, dateKey) => {
        return [record[dateKey]];
      });

      return resultItems;
    },
  },

  methods: {
    go() {
      this.$router.push({
        name: "scheduled.interviews",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    goBack() {
      // this.$router.push({
      //   name: "interviews.preview",
      //   params: {
      //     id: this.$route.params.id,
      //   },
      // });
      this.$router.back()
    },

    selectInterviewPage() {
      if (this.pageInterview === "approved_candidates") {
        this.$router.push({
          name: "approved.interviews",
          params: {
            id: this.$route.params.id,
          },
        });
      }
      if (this.pageInterview === "scheduled_interviews") {
        this.go();
      }
    },
  },

  mounted() {},
};
</script>

<style scoped>
.whirelab-conclud-folder {
  position: relative;
  display: inline-block;
}

.whirelab-conclud-folder .whirelab-conclud-tooltip {
  visibility: hidden;
  width: 210px;
  background-color: #757575;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 14px;
  padding: 5px 10px;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0;
  margin-left: -80px;
}

.whirelab-conclud-folder:hover .whirelab-conclud-tooltip {
  visibility: visible;
}
</style>
