import gql from "graphql-tag";
import entityFragment from "./EntityFragment";
import roleFragment from "./RoleFragment";
import userFragment from "./userFragment";

export const ENTITIES_QUERY = gql`
  query Entities {
    entities: entities {
      ...${entityFragment}
    }
  }
`;

export const ENTITY_QUERY = gql`
  query Entity {
    entity {
      ...${entityFragment}
    }
  }
`;

export const GET_PERMISSIONS_BY_TYPE_QUERY = gql`
  query GetPermissionsByType($type: String!) {
    permissions: getPermissionsByType(type: $type) {
      id
      description
      name
      status
      type
    }
  }
`;

export const GET_ROLES_ENTITY_QUERY = gql`
  query GetRolesEntity {
    roles: getRolesEntity {
      ...${roleFragment}
    }
  }
`;

export const GET_TOTAL_VACANCY_QUERY = gql`
  query GetTotalVacancy {
    getTotalVacancy {
      total
      totalViews
    }
  }
`;

export const GET_USERS_ENTITY_QUERY = gql`
  query GetUsersEntity {
    users: getUsersEntity {
      ...${userFragment}
    }
  }
`;
